// 语言引入
import i18n from '@/common/i18n';

const Contact = [
	{
		path: '/contact',
		name: 'contact',
		component: () => import('@/pages/contact/index.vue'),
		meta: {
			title: i18n.global.t('contact_us'),
			keepAlive: false
		},
		
	}
]

export default Contact;