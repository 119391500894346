import { createApp } from 'vue';
import App from './App.vue';

// 路由引入
import routes from './route/index';
// vuex引入
import Store from './store/index'
// 语言引入
import i18n from './common/i18n'

// 动画插件
import 'animate.css';
const app = createApp(App);


app.use(routes)
    .use(Store)
    .use(i18n)
    .mount('#app')
