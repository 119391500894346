import { resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Headers = _resolveComponent("Headers")!
  const _component_router_view = _resolveComponent("router-view")!
  const _component_Footers = _resolveComponent("Footers")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass({ lg_container: _ctx.lg, sm_container: _ctx.sm})
  }, [
    _createVNode(_component_Headers),
    _createVNode(_component_router_view),
    _createVNode(_component_Footers)
  ], 2))
}