/**
 * 根据sex状态判断男女
 * @param value
 * @returns {string|string}
 */
 const sexFilter = value => {
    if (value == '0') {
        return '保密'
    } else {
        const data = value % 2 === 1 ? '男' : '女'
        return data
    }
}

/**
 * rem转换
 */
export const setRootHtmlFontSize = () => {
    const deviceWidth = document.documentElement.clientWidth || document.body.clientWidth;
    document.querySelector('html').style.fontSize = deviceWidth / 7.5 + 'px';
};

/**
 * 身份证号脱敏('331082199708094687' 转换成 '33108219********87') 第8位开始替换8个
 * @param {Number} num 
 * @returns 
 */
const IDcardHide = num => {
    const data = num.replace(/(\d{8})\d{8}(\d*)/, '$1********$2')
    return data
}


/**
 * 手机号脱敏('13912345678' 转换成 '139****5678') 第3位开始替换4个
 * @param {number} num 
 * @returns 
 */
const telHide = num => {
    const data = num.replace(/(\d{3})\d{4}(\d*)/, '$1****$2')
    return data
}



/**
 * 姓名脱敏(小明 转换成 *明   李小明 转换成 李*明   欧阳小明 转换成 欧**明)
 * @param {number} name 
 * @returns 
 */
const nameHide = name => {
    if (name.length === 2) {
        return new Array(name.length).join('*') + name.substr(-1)
    } else {
        return (
            name.substr(0, 1) + new Array(name.length - 1).join('*') + name.substr(-1)
        )
    }
}

/**
 * 时间格式过滤
 * @param fmt
 * @param date
 * @returns {*}
 */
 const dateFilter = (fmt, date) => {
    let ret;
    const opt = {
        "Y+": date.getFullYear().toString(),        // 年
        "m+": (date.getMonth() + 1).toString(),     // 月
        "d+": date.getDate().toString(),            // 日
        "H+": date.getHours().toString(),           // 时
        "M+": date.getMinutes().toString(),         // 分
        "S+": date.getSeconds().toString()          // 秒
        // 有其他格式化字符需求可以继续添加，必须转化成字符串
    };
    for (let k in opt) {
        ret = new RegExp("(" + k + ")").exec(fmt);
        if (ret) {
            fmt = fmt.replace(ret[1], (ret[1].length == 1) ? (opt[k]) : (opt[k].padStart(ret[1].length, "0")))
        }
    }
    return fmt;
}

export default {
    sexFilter,
    IDcardHide,
    telHide,
    nameHide,
    dateFilter
}