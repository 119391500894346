import { createStore } from 'vuex'
const state = {
    cancelTokenArr: [] // 取消请求token数组
};

const mutations = {
    // 添加
    pushToken(state, val) {
        state.cancelTokenArr.push(val);
    },
    delToken(state) { 
        if (state.cancelTokenArr.length > 0) { 
            state.cancelTokenArr.splice(state.cancelTokenArr.length - 1, 1);
        }
    }
}


export default createStore({
    state,
    mutations
})