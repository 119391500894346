import {
	createRouter,
	createWebHistory
} from 'vue-router';

// 新闻路由
import Product from './configs/product';
// 公共配置
import Default from './configs/default';
// 联系我们
import Contact from './configs/contact'

// 路由加载的动画
import NProgress from 'nprogress';
import 'nprogress/nprogress.css';
NProgress.configure({
    easing: 'ease',  // 动画方式
    speed: 500,  // 递增进度条的速度
    showSpinner: false, // 是否显示加载ico
    trickleSpeed: 200, // 自动递增间隔
    minimum: 0.3 // 初始化时的最小百分比 
})

// 路由列表
const routes = [
	...Product,
	...Default,
	...Contact
];

/**
 * 实例化路由
 */
const router = createRouter({
	history: createWebHistory(),
	routes
})

// 监听路由加载前
// 显示动画
router.beforeEach((to, from, next) => {
	/* 路由发生变化修改页面title */
	if (to.meta.title) {
		// 动态修改title
		document.title = to.meta.title;
	}
    //
    document.documentElement.scrollTop = 0

	// 每次切换页面时，调用进度条
	NProgress.start();
	// 这个一定要加，没有next()页面不会跳转的
    next();
});
// 监听路由加载完成
router.afterEach((to, from) => {
	// 在即将进入新的页面组件前，关闭掉进度条
	NProgress.done();
})
export default router;