
import { MailFilled } from '@ant-design/icons-vue';
export default {
    components: {
        MailFilled
    },
    setup(props:any, content:any){
		
	}
}
