// 语言引入
import i18n from '@/common/i18n';

const Product = [
	{
		path: '/product',
		name: 'product',
        component: () => import('@/pages/product/index.vue'),
        // 嵌套路由，必须要在父页面中有 <router-view></router-view>
        children: [
            {
                path: '',
                name: 'product',
                component: () => import('@/pages/product/list'),
                meta: {
                    title: i18n.global.t('news.list'),
                    keepAlive: false
                },
            },
            {
                path: 'details/:id?',
                name: 'details',
                component: () => import('@/pages/product/details'),
                meta: {
                    title: i18n.global.t('news.details'),
                    keepAlive: false
                },
            }
        ]
	}
]

export default Product;