import i18n from '@/common/i18n';

const Default = [
	{
		path: '/',
		name: 'home',
		component: () => import('@/pages/index/index.vue'),
		meta: {
			title: i18n.global.t('web_title'),
			keepAlive: false
		},
		
	}
]

export default Default;