
import { ref, watch, defineComponent } from 'vue';
import { Menu } from "ant-design-vue";
import { useRoute } from 'vue-router';
const SubMenu = Menu.SubMenu;
const MenuItem = Menu.Item;
const MenuItemGroup = Menu.ItemGroup;
import { UnorderedListOutlined } from '@ant-design/icons-vue';
import { useWindowSize, onClickOutside } from '@vueuse/core';
export default defineComponent({
    components: {
        AMenu: Menu,
        ASubMenu: SubMenu,
        AMenuItem: MenuItem,
        AMenuItemGroup: MenuItemGroup,
        UnorderedListOutlined
    },
    
    setup(props: any, content: any) {
        const current = ref<string>('home');
        const { width, height } = useWindowSize();
        // 实例化路由参数
        let route = useRoute();
        
        watch([() => route.params, () => route.name],  ([param, routeName]) => {
            //要执行的方法
            var routeValue = '';
            if (routeName == 'details') {
                routeValue = param.id + '';
            } else { 
                routeValue = <string>routeName;
            }
            current.value = routeValue;
        },{ immediate: true })

        // 是否显示手机导航
        const mobilNavShow = ref<Boolean>(false);
        // 切换手机导航显示
        const showMenu = () => { 
            if (mobilNavShow.value) {
                mobilNavShow.value = false;
            } else { 
                mobilNavShow.value = true;
            }
        }

        // 点击除导航图标
        const modelEl = ref(null);
        const closeMobileNav = () => {
            mobilNavShow.value = false;
        }
         
        onClickOutside(modelEl, closeMobileNav);

        return {
            current,
            mobilNavShow,
            width,
            showMenu,
            modelEl
        };
	}
})
