
import { defineComponent, onMounted } from 'vue';
import Headers from '@/pages/layout/header.vue';
import Footers from '@/pages/layout/footer.vue';
import { useBreakpoints, breakpointsTailwind } from '@vueuse/core'
import { setRootHtmlFontSize } from '@/common/func.js';
export default defineComponent({
	components: {
		Headers,
		Footers
	},
	setup() {

		onMounted(() => {
            window.onresize = () => {
                return (() => {
                    setRootHtmlFontSize();
                })()
			}
			setRootHtmlFontSize();
        })

		// # 自适应
        const breakpoints = useBreakpoints(breakpointsTailwind)
        // # 自适应宽度变量
        // < 768
        const sm = breakpoints.smaller('md')
        // 768, 1280
		const lg = breakpoints.between('md', 'xl')
		
		return {
            sm,
			lg,
        }
	}
})
