export default{
    web_title: '中科强基',
    home: '首页',
    product_center: {
        zh: '解决方案',
        en: 'Product Center'
    },
    cooperative_partner: {
        zh: '合作伙伴',
        en: 'Cooperative Partner'
    },
    honorary_qualification: { 
        zh: '荣誉资质',
        en: 'Honorary  Qualification'
    },
    company_profile: {
        zh: '公司简介',
        en: 'Company Profile'
    },
    custom_service: {
        zh: '定制服务',
        en: 'Custom Service'
    },
    application_scenarios: {
        zh: '应用场景',
        en: 'Application Scenarios'
    },
    wisdom_view: {
        zh: '慧视',
        en: ''
    },
    wisdom_eye: {
        zh: '慧眼',
        en: ''
    },
    wisdom_voice: {
        zh: '慧声',
        en: ''
    },
    wisdom_energy: {
        zh: '慧能',
        en: ''
    },
    wisdom_control: {
        zh: '慧控',
        en: ''
    },
    product_features: '产品特点',
    development_mode: '开发方式',
    applicable_scenarios: '适用场景',

    contact_us: '联系我们',

    more_text: '了解更多',
    news: {
        list: '新闻列表',
        details: '新闻详情'
    }
}