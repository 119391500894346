import { resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-324a7a20"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "container" }
const _hoisted_2 = { class: "headers" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_HeadNav = _resolveComponent("HeadNav")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    ($setup.width > 1024)
      ? (_openBlock(), _createElementBlock("div", {
          key: 0,
          class: _normalizeClass(["headers scrollHeader", {adsorbHeader: $setup.y > 500}])
        }, [
          _createVNode(_component_HeadNav)
        ], 2))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_HeadNav)
    ])
  ]))
}