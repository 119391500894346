
import { useWindowScroll, useWindowSize } from '@vueuse/core';
import HeadNav from '@/components/heads.vue';
export default {
    components: {
		HeadNav
	},
    setup(props: any, content: any) {
        const { y } = useWindowScroll();
        const { width, height } = useWindowSize();  
        return {
            y,
            width
        }
	}
}
